<template>
  <v-container id="regular-tables-view" fluid tag="section">
    <v-alert
      v-if="alert.visible"
      color="primary"
      dark
      icon="fa-regular fa-hexagon-exclamation"
      border="left"
      prominent
    >
      {{ alert.description }}
      <v-btn
        v-if="alert.button.visible"
        color="secondary"
        elevation="2"
        @click="alert.button.cl"
      >
        <i v-if="alert.button.icon" :class="alert.button.icon"></i>
        {{ alert.button.text }}
      </v-btn>
    </v-alert>

    <view-intro class="text-start" :heading="section"/>

    <div class="mb-4">
      <v-btn
        color="primary"
        elevation="2"
        outlined
        @click="$router.go(-1)"
      >&lArr; Terug
      </v-btn>
      <v-btn
        class="ml-4"
        elevation="2"
        icon
        color="orange"
        outlined
        @click="dialog.visible = true;"
      >
        <i class="fa-regular fa-image fa-xl"></i>
      </v-btn>
    </div>
    <div v-if="loading" class="d-flex justify-content-center" style="margin-top: 30vh;">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-else>

      <material-card
        class="mt-6 elevation-1 mt-16"
        icon="fa-regular fa-square"
        icon-small
        :title="section + ' vak'"
        color="accent"
      >
        <v-data-table
          loading-text="Laden even geduld aub"
          :headers="headers"
          @click:row="goToProduct"
          :items="sectionProducts"
          :items-per-page="10"
        ></v-data-table>
      </material-card>
      <div class="mt-4">
        <v-btn
          color="primary"
          elevation="2"
          outlined
          @click="$router.go(-1)"
        >&lArr; Terug
        </v-btn>
      </div>
    </div>
    <v-dialog v-model="dialog.visible" max-width="500">
      <v-card class="text-center">
        <v-card-title>
          <v-icon aria-label="Close" @click="dialog.visible = false;">
            mdi-close
          </v-icon>
        </v-card-title>

        <FormulateInput
          style="display: flex;flex-direction: column;align-items: center;"
          type="image"
          name="headshot"
          label="Selecteer een afbeelding om te uploaden"
          help="Select a png, jpg, or gif to upload."
          validation="mime:image/jpeg,image/png,image/heic"
          @file-upload-complete="saveImage"
          @file-removed="image = 'removed'"
          @file-upload-progress="dialog.disabled = true"
          class="mt-6 mb-3"
          :value="[initialImage]"
        ></FormulateInput>

        <v-card-actions>
          <v-spacer/>
          <v-btn color="blue" text @click="dialog.visible = false;">
            Annuleren
          </v-btn>
          <v-btn color="blue" text @click="setImage" :loading="dialog.loading" :disabled="dialog.disabled">
            Opslaan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  export default {
    name: 'vakkenDetail',
    data () {
      let { section } = this.$route.params
      section = section.replace('%2F', '/').replace('%23\t', '#')
      if (!section) {
        this.$router.push('/section')
      }
      this.$auth.getProductsBySection(section).then(async products => {
        this.sectionProducts = this.$filter.filterProducts(products)
        const imageUrl = await this.$auth.getImage('vak_' + section)
        if(imageUrl) {
          this.initialImage.url = imageUrl + '.jpg'
          this.initialImage.name = section + ' vak foto'
        }
      }).catch(err => {
        this.alert.visable = true
        this.alert.description = `Er is iets mis gegaan error: ${err} Neem graag contact met beheerder`
      }).finally(() => {
        this.loading = false
      })
      return {
        initialImage: {
          url: null,
          name: null,
        },
        image: null,
        loading: true,
        section,
        dialog: {
          visible: false,
          loading: false,
          disabled: false,
        },
        sectionProducts: [],
        headers: [
          {
            text: 'Naam',
            value: 'name',
          },
          {
            text: 'Prijs',
            sortable: false,
            value: 'price',
          },
          {
            text: 'Groep',
            value: 'document',
          },
        ],
        alert: {
          visible: false,
          description: '',
          button: {
            visible: false,
            text: null,
            cl: null,
          },
        },
      }
    }
    ,
    methods: {
      saveImage (image) {
        this.image = image.previewData
        this.dialog.disabled = false
      },
      async setImage () {
        if (!this.image) return
        this.dialog.loading = true
        let blob = null
        if (this.image !== 'removed') {
          const base64Response = await fetch(this.image)
          blob = await base64Response.blob()
          await this.$filter.compressProductImage(blob, this.addVakImage)
          return
        }
        this.addVakImage(blob)
      },
      addVakImage (blob) {
        this.$auth.addVakImage(this.section, blob).then(() => {
          this.dialog.loading = false
          this.$swal({
            icon: 'success',
            title: 'Opgeslagen',
          })
        }).catch(err => {
          console.log(err)
          this.$swal({
            icon: 'error',
            title: 'Er is iets mis gegaan',
            text: 'Deze vak kon niet bijgewerkt worden',
          })
        })
      },

      goToProduct (product) {
        const {
          id,
          document,
        } = product
        this.$router.push(`/products/${document}/${id}`)
      },
    },
  }
</script>
